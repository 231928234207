import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Gap from "../elements/gap.tsx";
import Text from "../elements/text.tsx";
import ImageText from "../elements/imagetext.tsx";
import Annotation from "../elements/annotation.tsx";
import Title from "../elements/title.tsx";
import Images from "../elements/images.tsx";
import TextLayout from "../elements/textlayout.tsx";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import PhotoGallery from "../elements/gallery.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <TextLayout mdxType="TextLayout">
  <h4>Narrative Tool</h4>
  <h5>
    Empower healthcare researchers to build engaging storytelling survey with
    ease
  </h5>
  <br />
  <br />
  2023 <br />
  Individual
  <br />
  Client: <a href="https://www.unusualcollaborations.com/the-power-of-one">
    {" "}
    Center for Unusual Collaboration, the Netherlands
  </a>
  <br />
  Keywords: programming, data visualisation, UI/UX design
  <iframe width="100%" height="500" src="https://www.youtube.com/embed/pvB0T006fnY?si=m5OVOwVoNlrbSW7H" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
    </TextLayout>
    <Gap margin={30} mdxType="Gap" />
    <PhotoGallery photos={[{
      src: "../../../narrative/title.jpg",
      width: 7016,
      height: 4961
    }]} mdxType="PhotoGallery" />
    <PhotoGallery photos={[{
      src: "../../../narrative/demo1.jpg",
      width: 7016,
      height: 4961
    }, {
      src: "../../../narrative/demo3.jpg",
      width: 7016,
      height: 4961
    }]} mdxType="PhotoGallery" />
    <TextLayout mdxType="TextLayout"> 
      <Text title="Description" mdxType="Text">
  Stories have been used as a resource in design. They are a great way to put user values center
  stage, and to develop technology solutions that prioritise those values.
  However, design stories and scenarios are often constructed within the design
  studio, or with a limited set of users. 
  <br />
  <br />
  This project aims to design and develop a narrative tool that empowers researchers to build engaging storytelling surveys with a simplified workflow while also providing a user-friendly dashboard for visualising and tracking participants' responses. 
      </Text>
      <Text title="Design System" mdxType="Text">
  <img src="../../../narrative/designsystem.jpg" />
      </Text>
      <Text title="Constructing Narrative Stories" mdxType="Text">
  I have selected <a href="https://twinery.org/">Twine</a>, an open-source tool
  designed for creating interactive and nonlinear narratives, as a foundation.
  The interface is then refined with customised css based on requirements from
  the clients.
      </Text>
      <Text title="Data Tracking and Visualisation" mdxType="Text">
  Every decision made by the participants within the story is monitored and transferred to a server. The dashboard then utilises this tracking data and presents it in a comprehensible way for the researchers.
  <br />
  <br />
  I selected tree structures to represent the tracking data, in accordance with the structure of a narrative survey. Each distinct pathway is depicted individually or merged with others when they overlap. The size of the circles corresponds to the number of participants who chose a specific option, and comments were collected and categorised as well.
   <img src="../../../narrative/demo2.jpg" />
  <br />
  <br />
  Technology wise, this dashboard is developed with Javascript, the React framework and CSS. A data visualisation library from <a href="https://d3js.org/">D3.js</a> was utilised, along with breadth-first search (BFS) algorithms to navigate traverse and merge data in a tree structure. The source code for this project can be found in my <a href="https://github.com/void-kuangyi/Narrative-Tool">GitHub repository</a>
      </Text>
    </TextLayout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      